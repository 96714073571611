import { Combobox } from '@kobalte/core/combobox';
import { useLocation, useNavigate } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Picture } from '@troon/ui';
import { IconUnfoldMore } from '@troon/icons/unfold-more';
import { useCurrentFacility, useOperatorFacilities } from '../providers/root';
import type { ComponentProps } from 'solid-js';
import type { Facilities } from '../providers/root';

export function FacilitySelector(props: { onSelect?: () => void }) {
	const facilities = useOperatorFacilities();
	const navigate = useNavigate();
	const location = useLocation();
	const [open, onOpenChange] = createSignal(false);
	const [value, setValue] = createSignal<Facilities[number]['facility'] | null>();

	const currentFacility = useCurrentFacility();

	return (
		<Combobox<Facilities[number]['facility']>
			onChange={(item) => {
				if (item) {
					props.onSelect && props.onSelect();
					navigate(
						location.pathname.startsWith('/facility')
							? `/facility/${item.slug}${location.pathname.replace(new RegExp(`^/facility/${currentFacility()?.facility.slug}`), '')}`
							: `/facility/${item.slug}`,
					);
					// Wait a tick to reset the value to null
					// Ensure that re-opening the list will show all values again
					setTimeout(() => {
						setValue(null);
					}, 60);
				}
			}}
			onInputChange={(value) => {
				if (value === '') {
					setValue(null);
				}
			}}
			options={facilities()?.map((f) => f.facility) ?? []}
			optionValue="slug"
			optionLabel="name"
			optionTextValue="name"
			itemComponent={Facility}
			sameWidth
			fitViewport
			gutter={1}
			closeOnSelection
			selectionBehavior="replace"
			open={open()}
			onOpenChange={onOpenChange}
			value={value()}
			allowsEmptyCollection
			tabIndex={-1}
		>
			<Combobox.Control class="group relative overflow-hidden rounded border border-neutral focus-within:ring focus-within:ring-brand-100">
				<Combobox.Input
					type="search"
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="absolute inset-0 left-[calc(4rem+4px)] z-20 cursor-pointer bg-white p-4 opacity-0 outline-none"
					classList={{
						'opacity-0': !open(),
						'opacity-1': open(),
					}}
					onFocus={(event) => {
						onOpenChange(true);
						event.currentTarget.select();
					}}
					tabIndex={-1}
				/>
				<Combobox.Trigger class="relative z-10 flex w-full items-center gap-3 p-3 text-start">
					<span class="size-14 shrink-0 overflow-hidden rounded-md border border-neutral p-1">
						<Show when={currentFacility()?.facility.logo}>
							{(logo) => (
								<Picture
									src={logo()}
									alt=""
									height={100}
									width={100}
									sizes="200px"
									crop="center"
									mode="contain"
									class="w-full"
								/>
							)}
						</Show>
					</span>
					<span class="grow truncate">{currentFacility()?.facility.name ?? 'Select a facility'}</span>
					<IconUnfoldMore class="shrink-0 text-brand" />
				</Combobox.Trigger>
			</Combobox.Control>
			<Combobox.Portal>
				<Combobox.Content
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="z-50 flex max-h-[calc(var(--kb-popper-content-available-height)-1rem)] w-[var(--kb-popper-anchor-width)] flex-col overflow-y-auto overflow-x-hidden overscroll-contain rounded border border-neutral-300 bg-white shadow-md"
				>
					<Combobox.Listbox class="flex flex-col gap-1 p-2" />
				</Combobox.Content>
			</Combobox.Portal>
		</Combobox>
	);
}

const Facility: ComponentProps<typeof Combobox<Facilities[number]['facility']>>['itemComponent'] = (props) => {
	return (
		<Combobox.Item
			item={props.item}
			class="cursor-pointer rounded-md px-4 py-2 hover:bg-brand-100 ui-highlighted:bg-brand-100"
		>
			{props.item.rawValue.name}
		</Combobox.Item>
	);
};
